@font-face {
	font-family: 'KasperskySans';
	src: url('EOT/KasperskySans-Regular.eot');
	src: local('KasperskySans-Regular'),
		url('EOT/KasperskySans-Regular.eot?#iefix') format('embedded-opentype'),
		url('WOFF/KasperskySans-Regular.woff') format('woff'),
		url('WOFF2/KasperskySans-Regular.woff2') format('woff2'),
		url('TTF/KasperskySans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KasperskySans';
	src: url('EOT/KasperskySans-Light.eot');
	src: local('KasperskySans-Light'),
	url('EOT/KasperskySans-Light.eot?#iefix') format('embedded-opentype'),
	url('WOFF/KasperskySans-Light.woff') format('woff'),
	url('WOFF2/KasperskySans-Light.woff2') format('woff2'),
	url('TTF/KasperskySans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'KasperskySans';
	src: url('EOT/KasperskySans-Bold.eot');
	src: local('KasperskySans-Bold'),
	url('EOT/KasperskySans-Bold.eot?#iefix') format('embedded-opentype'),
	url('WOFF/KasperskySans-Bold.woff') format('woff'),
	url('WOFF2/KasperskySans-Bold.woff2') format('woff2'),
	url('TTF/KasperskySans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}