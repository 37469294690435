* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #1d1d1b;
  font-family: "KasperskySans";
  font-size: 16px;
  line-height: normal;
}

body {
  background-color: #ffffff;
}

.justify-wrap {
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container {
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
}

.wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100%;
}

.promo__slider-item {
  height: 768px;
  position: relative;
  color: #1d1d1d;
}
.promo__slider-item .container {
  position: relative;
  z-index: 2;
  padding-bottom: 80px;
  height: 100%;
}

.promo__slider-bg {
  background: linear-gradient(90deg, #23d1ae 0%, #7eff33 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.promo__slider-bg:before {
  content: "";
  position: absolute;
  background: url("../img/decor-1.svg") no-repeat center;
  background-size: contain;
  width: 822px;
  height: 830px;
  top: 210px;
  left: 50%;
  margin-left: -914px;
}

.promo__content {
  max-width: 790px;
}
.promo__content .actions__wrap {
  margin-top: 48px;
}

.promo__title {
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -0.01em;
}

.promo__descr {
  max-width: 590px;
  font-size: 21px;
  line-height: 24px;
}

.btn__custom {
  height: 48px;
  padding: 0 30px;
  min-width: 152px;
  background: #ff3748;
  box-shadow: 0px 8px 32px rgba(255, 55, 72, 0.32);
  border-radius: 3px;
  color: #fff !important;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.btn__custom:hover {
  background: #ed2939;
  box-shadow: 0px 8px 32px rgba(255, 55, 72, 0.4);
}
.btn__custom:active {
  background: #ed2939;
  box-shadow: none;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 104px;
  width: 100%;
  z-index: 100;
}
.header .container {
  height: 100%;
}

.logo {
  width: 95px;
}
.logo img {
  display: block;
  width: 100%;
}

.main__nav {
  margin-right: 32px;
}
.main__nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
}
.main__nav ul li {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
}
.main__nav ul li:not(:last-child) {
  margin-right: 32px;
  margin-right: 24px;
}
.main__nav ul li a {
  color: #fff !important;
  position: relative;
}
.main__nav ul li a:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 3px;
  margin-top: 11px;
  opacity: 0;
  background: #fff;
}
.main__nav ul li a:hover {
  color: #1d1d1b !important;
}
.main__nav ul li.active a:after {
  opacity: 1;
}

.search__toggle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.search__toggle svg {
  display: block;
  width: 100%;
  height: 100%;
}
.search__toggle:hover path {
  fill: #1d1d1b;
}

.search__box {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -32px;
  height: 64px;
  opacity: 0;
  visibility: hidden;
}

.search-mode .search__box {
  opacity: 1;
  visibility: visible;
}
.search-mode .main__nav,
.search-mode .search__toggle {
  opacity: 0;
  visibility: hidden;
}
.search-mode .mode__nav {
  opacity: 0;
  visibility: hidden;
}

.header__right {
  position: relative;
}

.search__submit {
  cursor: pointer;
  width: 20px;
  min-width: 20px;
  margin-right: 8px;
}
.search__submit svg {
  display: block;
  width: 100%;
  height: 100%;
}
.search__submit:hover path {
  fill: #1d1d1b;
}

.search__field {
  width: 100%;
}
.search__field input {
  height: 64px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #23d1ae;
  padding-right: 64px;
  font-weight: 300;
  font-size: 16px;
  background: transparent;
  color: #fff;
}
.search__field input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.search__field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.search__field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.search__field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.search__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.search__close svg {
  width: 100%;
  height: 100%;
  display: block;
}
.search__close:hover path {
  fill: #1d1d1b;
}

.mode__nav ul {
  list-style: none;
  font-size: 0;
  padding: 0;
  margin: 0;
  display: flex;
}
.mode__nav ul li {
  /* display: inline-block; */
  /* vertical-align: top; */
  width: 98px;
  height: 32px;
}
.mode__nav ul li a {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  border: 2px solid #fff;
  height: 32px;
}
.mode__nav ul li a:hover {
  color: #1d1d1b;
}
.mode__nav ul li:first-child a {
  border-radius: 16px 0 0 16px;
}
.mode__nav ul li:last-child a {
  border-radius: 0 16px 16px 0;
}
.mode__nav ul li.active a {
  background: #fff;
  color: #1d1d1b;
}

.page-front .mode__nav ul li a {
  color: #1d1d1d;
  border: 2px solid #1d1d1d;
}
.page-front .mode__nav ul li a:hover {
  color: #1d1d1d;
}
.page-front .mode__nav ul li.active a {
  background: #1d1d1d;
  color: #fff;
}
.page-front .search__toggle path,
.page-front .search__close path,
.page-front .search__submit path {
  fill: #1d1d1d;
}
.page-front .search__field input {
  border-bottom: 2px solid #1d1d1d;
  color: #1d1d1d;
}
.page-front .search__field input::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.75);
}
.page-front .search__field input:-moz-placeholder {
  color: rgba(29, 29, 29, 0.75);
}
.page-front .search__field input::-moz-placeholder {
  color: rgba(29, 29, 29, 0.75);
}
.page-front .search__field input:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.75);
}
.page-front .main__nav ul li a {
  color: #1d1d1d !important;
}
.page-front .main__nav ul li a:hover {
  color: #1d1d1b !important;
}
.page-front .main__nav ul li a:after {
  background: #1d1d1b;
}

.subscribe__form {
  position: relative;
}
.subscribe__form .success {
  position: absolute;
  color: #00a88e;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}
.subscribe__form.success-mode .subscribe__field,
.subscribe__form.success-mode .actions__wrap {
  opacity: 0;
}
.subscribe__form.success-mode .success {
  opacity: 1;
  visibility: visible;
}

.dark-theme .nav__bars path {
  fill: #1d1d1d;
}

.checkbox__wrap.success-mode {
  opacity: 0;
}

.actions__wrap.disabled {
  position: relative;
}
.actions__wrap.disabled:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.actions__wrap.disabled:after {
  content: "";
  background: url("../img/loader.svg") no-repeat center;
  background-size: contain;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  z-index: 5;
}

.sc__promo .slide__prev path {
  fill: #1d1d1d;
}
.sc__promo .slide__prev rect {
  fill: #1d1d1d;
}
.sc__promo .slide__prev:hover path {
  stroke: none;
  fill: #ff3748;
}
.sc__promo .slide__prev:hover rect {
  fill: #ff3748;
}
.sc__promo .slide__next path {
  stroke: #1d1d1d;
}
.sc__promo .slide__next rect {
  fill: #1d1d1d;
}
.sc__promo .slide__next:hover path {
  fill: none;
  stroke: #ff3748;
}
.sc__promo .slide__next:hover rect {
  fill: #ff3748;
}

.search__toggle {
  margin-right: 32px;
}

.promo__slider-box {
  position: relative;
}

.slide__navs {
  position: absolute;
  right: 50%;
  margin-right: -600px;
  bottom: 80px;
  z-index: 10;
}

.slide__nav {
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.slide__nav svg {
  display: block;
  width: 100%;
  height: 100%;
}
.slide__nav:hover path {
  fill: #ff3748;
  stroke: #ff3748;
}

.promo__img {
  position: absolute;
  left: 50%;
  width: 800px;
  margin-left: -260px;
  top: 27px;
}
.promo__img img {
  display: block;
  width: 100%;
}

.slide__info {
  position: absolute;
  right: 16px;
  bottom: 100%;
  color: #1d1d1d;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.slide__info-current {
  font-size: 28px;
  line-height: 32px;
}

.row__custom {
  margin-left: -16px;
  margin-right: -16px;
}

.col__custom {
  padding-right: 16px;
  padding-left: 16px;
}

.sc__common .container {
  padding-top: 64px;
  padding-bottom: 64px;
}

.article__item-1 .article__item-block {
  height: 398px;
}
.article__item-1 .article__item-img:after {
  background: linear-gradient(
    180deg,
    rgba(120, 255, 51, 0.29) 0%,
    rgba(126, 255, 51, 0) 100%
  );
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}

.article__item-2 .article__item-block {
  height: 644px;
}
.article__item-2 .article__item-img:after {
  background: radial-gradient(
    72.66% 72.66% at 27.34% 0%,
    rgba(126, 255, 51, 0.32) 0%,
    rgba(126, 255, 51, 0) 100%
  );
  content: "";
  top: 0;
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
}

.article__item-3 .article__item-block {
  height: 644px;
  align-items: center;
}
.article__item-3 .article__item-img:before {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(120, 255, 51, 0.16) 0%,
    rgba(126, 255, 51, 0) 100%
  );
  content: "";
  top: 0;
  height: 382px;
  width: 382px;
  position: absolute;
  left: 0;
}
.article__item-3 .article__item-img:after {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(120, 255, 51, 0.16) 0%,
    rgba(126, 255, 51, 0) 100%
  );
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  content: "";
  bottom: 0;
  height: 382px;
  width: 382px;
  position: absolute;
  right: 0;
}

.article__item-4 .article__item-block {
  height: 398px;
}
.article__item-4 .article__item-img:after {
  background: rgba(29, 29, 27, 0.4);
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}

.article__item-block {
  position: relative;
  overflow: hidden;
  display: block;
}
.article__item-block:hover {
  box-shadow: 0px 8px 32px rgba(52, 208, 184, 0.48);
}

.article__item-img {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
}
.article__item-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article__item-content {
  padding: 32px;
  width: 100%;
  z-index: 2;
}

.article__item-category {
  text-transform: uppercase;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
}

.article__item-title {
  font-size: 36px;
  line-height: 40px;
}

.article__item {
  margin-bottom: 32px;
}

.articles__list .row {
  margin-bottom: -32px;
}

.article__item-5 .article__item-block {
  align-items: center;
  height: 379px;
}
.article__item-5 .article__item-content {
  max-width: 588px;
}

.article__item-4 .article__item-title {
  font-size: 21px;
  font-weight: normal;
  line-height: 24px;
}

.article__item-decor {
  position: absolute;
  top: 33px;
  width: 428px;
  left: 50%;
  margin-left: -30px;
}
.article__item-decor img {
  display: block;
  width: 100%;
}

.sc__articles .actions__wrap {
  margin-top: 45px;
}

.btn__border {
  height: 48px;
  padding: 0 24px;
  color: #00a88e !important;
  font-size: 16px;
  border-radius: 3px;
  border: 2px solid #cccccc;
}
.btn__border:hover {
  border: 2px solid #bde0f5;
  color: #23d1ae !important;
}
.btn__border:hover path {
  fill: #23d1ae;
}
.btn__border:active {
  border: 2px solid #aeaeae;
  color: #265951 !important;
}
.btn__border:active path {
  fill: #265951;
}

.icon__arrow {
  margin-left: 4px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
.icon__arrow svg {
  display: block;
  width: 100%;
  height: 100%;
}

.sc__product-front {
  background: linear-gradient(90deg, #7eff33 0%, #23d1ae 100%);
}
.sc__product-front .container {
  padding-top: 96px;
  padding-bottom: 96px;
}

.sc__header {
  margin-bottom: 32px;
}

.sc__title {
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -0.03em;
}

.icon__item-img {
  width: 44px;
  min-width: 44px;
  margin-left: -2px;
  margin-right: 20px;
}
.icon__item-img img {
  display: block;
  width: 100%;
}

.icon__item:not(:last-child) {
  margin-bottom: 32px;
}

.icon__item-descr {
  font-size: 21px;
  line-height: 24px;
  max-width: 418px;
}

.icon__list {
  margin-bottom: 48px;
}

.sc__product-front {
  position: relative;
  overflow: hidden;
}
.sc__product-front:before {
  background: url("../img/decor-2.svg") no-repeat center;
  background-size: contain;
  width: 1370px;
  height: 632px;
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -685px;
  top: 50%;
  margin-top: -370px;
}
.sc__product-front .btn__custom {
  padding-left: 16px;
  padding-right: 16px;
}
.sc__product-front .container {
  position: relative;
}
.sc__product-front .static__img {
  position: absolute;
  width: 768px;
  bottom: 0;
  margin-left: -702px;
  left: 50%;
}
.sc__product-front .static__img img {
  display: block;
  width: 100%;
}

.footer {
  background: #fff;
  position: relative;
  overflow: hidden;
  margin: 0 auto 0;
  z-index: 2;
}
.footer .container {
  height: 100%;
}

.footer__logo {
  width: 156px;
}
.footer__logo img {
  display: block;
  width: 100%;
}

.footer__logo-box {
  margin-right: 16px;
}

.footer__info ul {
  font-size: 0;
  margin: 0;
  padding: 0;
}
.footer__info ul li {
  display: inline-block;
  vertical-align: top;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}
.footer__info ul li:not(:last-child) {
  margin-right: 20px;
  position: relative;
}
.footer__info ul li:not(:last-child):after {
  content: "\2022";
  right: -12px;
  top: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
  position: absolute;
}
.footer__info ul li a {
  color: #00a88e !important;
}
.footer__info ul li a:hover {
  color: #1d1d1b !important;
}

.footer__info {
  margin-right: auto;
  margin-left: 0;
}

.social a {
  width: 24px;
  height: 24px;
}
.social a:hover path {
  fill: #00a88e;
}
.social a svg {
  width: 100%;
  height: 100%;
  display: block;
}
.social a:not(:last-child) {
  margin-right: 8px;
}

.sc__articles {
  position: relative;
  overflow: hidden;
}

.decor-3 {
  background: url("../img/decor-3.svg") no-repeat;
  background-size: contain;
  width: 1044px;
  margin-left: -1123px;
  top: -594px;
  left: 50%;
  position: absolute;
  height: 1388px;
}

.decor-4 {
  background: url("../img/decor-3.svg") no-repeat;
  background-size: contain;
  width: 1144px;
  margin-left: -1023px;
  bottom: -214px;
  left: 50%;
  position: absolute;
  height: 1388px;
}

.decor-5 {
  background: url("/img/decor-5.svg ") no-repeat;
  background-size: contain;
  width: 980px;
  margin-left: 142px;
  bottom: 298px;
  left: 50%;
  position: absolute;
  height: 1045px;
}

.sc__promo {
  position: relative;
  z-index: 2;
}

.article__bg-box {
  position: relative;
  background: #1d1d1b;
  height: 768px;
}
.article__bg-box .article__bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.75;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.article__post {
  margin-top: -160px;
  position: relative;
  z-index: 2;
}

.article__info {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.article__block-descr {
  background: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 80px;
}

.seo__item > * {
  font: inherit !important;
  margin: 0;
}

.article__description {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}

.article__text > *:first-child {
  margin-top: 0 !important;
}
.article__text > *:last-child {
  margin-bottom: 0 !important;
}
.article__text figure {
  margin: 0 0 32px 0;
}
.article__text figure iframe {
  width: 100% !important;
  height: 431px !important;
}
.article__text h3 {
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  margin: 24px 0 24px 0;
}
.article__text p {
  margin: 0 0 32px 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
.article__text div[data-block="true"] {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 32px 0;
}
.article__text img {
  display: block;
  max-width: 100%;
  margin: 48px auto 64px;
}
.article__text h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0 16px 0;
}
.article__text h1 {
  font-weight: bold;
  font-size: 72px;
  line-height: 52px;
  letter-spacing: -0.03em;
  color: #0b2734;
  margin: 48px 0 32px 0;
}
.article__text h2 {
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  color: #0b2734;
  letter-spacing: -0.03em;
  margin: 32px 0 24px 0;
}
.article__text blockquote {
  margin: 48px 0;
  padding: 24px;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -0.03em;
  color: #1d1d1b;
}
.article__text blockquote:before {
  content: "";
  left: 0;
  bottom: 0;
  width: 46px;
  height: 44px;
  background: url("../img/quote-left.svg") no-repeat center;
  background-size: contain;
  position: absolute;
}
.article__text blockquote:after {
  content: "";
  right: 0;
  top: 0;
  width: 46px;
  height: 44px;
  background: url("../img/quote-rught.svg") no-repeat center;
  background-size: contain;
  position: absolute;
}
.article__text ul {
  margin: 0 0 32px 0;
  list-style: none;
  padding: 0;
}
.article__text ul li {
  position: relative;
  padding-left: 26px;
  color: #1d1d1b;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
.article__text ul li:before {
  content: "";
  background: #00a88e;
  position: absolute;
  left: 0;
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 100%;
}
.article__text ul li:not(:last-child) {
  margin-bottom: 16px;
}
.article__text p a {
  color: #00a88e !important;
}
.article__text p a:hover {
  color: #23d1ae !important;
}
.article__text ol {
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
  counter-reset: list 0;
}
.article__text ol li {
  position: relative;
  padding-left: 52px;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #1d1d1b;
  min-height: 54px;
}
.article__text ol li:not(:last-child) {
  margin-bottom: 40px;
}
.article__text ol li:before {
  counter-increment: list;
  content: counter(list);
  position: absolute;
  top: -4px;
  left: 0;
  font-weight: bold;
  font-size: 54px;
  line-height: 54px;
  letter-spacing: -0.03em;
}

.article__post-box .container {
  padding-bottom: 120px;
}

.video__box iframe {
  display: block;
  width: 100%;
  border: none !important;
}

.redirect__box-img {
  width: 152px;
  min-width: 152px;
  margin-right: 52px;
}
.redirect__box-img a {
  display: block;
}
.redirect__box-img a:hover {
  opacity: 0.75;
}
.redirect__box-img img {
  display: block;
  width: 100%;
}

.redirect__box-title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #00a88e;
}
.redirect__box-title a {
  color: #00a88e;
}
.redirect__box-title a:hover {
  color: #1d1d1b !important;
}

.redirect__box-descr {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}

.redirect__box {
  margin-bottom: 32px;
  margin-top: 32px;
}

.article__text-box-indent {
  margin-top: 120px;
}

.steps__item {
  margin-bottom: 40px;
}

.steps__list .row {
  margin-bottom: -40px;
}

.steps__box {
  margin-top: 40px;
  margin-bottom: 64px;
}

.steps__item-content {
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}
.steps__item-content b {
  font-weight: bold;
  display: block;
  line-height: 24px;
}
.steps__item-content p {
  margin: 0 0 16px 0;
}
.steps__item-content > *:last-child {
  margin-bottom: 0 !important;
}

.article__header {
  position: relative;
}

.steps__item-content {
  max-width: 295px;
}

.steps__item-numb {
  margin-top: -6px;
  min-width: 56px;
  width: 56px;
  font-size: 54px;
  font-weight: bold;
  line-height: 54px;
  letter-spacing: -0.03em;
}

.article__title {
  position: absolute;
  bottom: 100%;
  margin-bottom: 44px;
  font-weight: bold;
  font-size: 72px;
  line-height: 72px;
  color: #fff;
  letter-spacing: -0.01em;
}

.article__post-box {
  position: relative;
}

.decor-6 {
  position: absolute;
  background: url("../img/decor-6.svg") no-repeat center;
  background-size: contain;
  width: 1889px;
  height: 2084px;
  left: 50%;
  top: -700px;
  margin-left: -1360px;
}

.decor-7 {
  position: absolute;
  background: url("../img/decor-7.svg") no-repeat center;
  background-size: contain;
  width: 957px;
  height: 1183px;
  left: 50%;
  top: -270px;
  margin-left: 165px;
}

.article__bg-box {
  z-index: 2;
}

.decor-8 {
  width: 1307px;
  height: 1320px;
  position: absolute;
  background: url("../img/decor-8.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  top: 40%;
  margin-left: 470px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.decor-9 {
  width: 1061px;
  height: 1070px;
  position: absolute;
  background: url("../img/decor-9.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  bottom: 15%;
  margin-left: -1330px;
}

.sc__other {
  background: #1d1d1b;
  position: relative;
  overflow: hidden;
}
.sc__other .container {
  padding-top: 106px;
  padding-bottom: 66px;
}
.sc__other .sc__descr {
  color: #fff;
}
.sc__other .sc__title {
  font-size: 64px;
  line-height: 68px;
  color: #fff;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}
.sc__other .sc__title a:hover {
  opacity: 0.65;
}
.sc__other .sc__header {
  margin-bottom: 32px;
}

.sc__bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}

.sc__descr {
  margin-bottom: 48px;
  font-size: 21px;
  line-height: 24px;
}

.btn__custom-green {
  background: #00a88e;
  box-shadow: 0px 8px 32px rgba(52, 208, 184, 0.48);
}
.btn__custom-green:hover {
  background: #23d1ae;
  box-shadow: 0px 12px 40px rgba(21, 190, 164, 0.48);
}
.btn__custom-green:active {
  background: #00a88e;
  box-shadow: none;
}

.decor-10 {
  position: absolute;
  left: 50%;
  background: url("../img/decor-10.svg") no-repeat center;
  background-size: cover;
  margin-left: 293px;
  top: -181px;
  width: 1059px;
  height: 1070px;
}

.sc__product-front-small {
  display: none !important;
}
.sc__product-front-small .container {
  padding-top: 68px;
  padding-bottom: 64px;
}
.sc__product-front-small .icon__list,
.sc__product-front-small .actions__wrap {
  opacity: 0;
  visibility: hidden;
}
.sc__product-front-small .static__img {
  position: absolute;
  width: 553px;
  bottom: 0;
  margin-left: -405px;
  left: 50%;
}
.sc__product-front-small .sc__content {
  position: relative;
  z-index: 2;
}
.sc__product-front-small .sc__header {
  position: absolute;
  left: 0;
  top: 0;
}
.sc__product-front-small .sc__title {
  font-size: 64px;
  line-height: 68px;
  letter-spacing: -0.01em;
}
.sc__product-front-small .col__custom {
  position: inherit;
}

.sc__product {
  background: #00a88e;
}
.sc__product .container {
  padding-top: 144px;
  padding-bottom: 72px;
}

.product__title {
  font-weight: bold;
  font-size: 64px;
  line-height: 68px;
  letter-spacing: -0.03em;
  margin-bottom: 24px;
  color: #fff;
}

.product__descr {
  margin-bottom: 15px;
  font-size: 21px;
  line-height: 24px;
  color: #fff;
  max-width: 320px;
}

.sc__product .actions__wrap {
  max-width: 320px;
}

.btn__border-white {
  color: #fff !important;
  border-color: #fff;
  box-shadow: 0px 8px 32px rgba(52, 208, 184, 0.32);
}
.btn__border-white:hover {
  color: #fff !important;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.sc__product {
  overflow: hidden;
  position: relative;
}
.sc__product .static__img {
  position: absolute;
  width: 553px;
  margin-left: -36px;
  bottom: 0;
  left: 50%;
  z-index: 2;
}
.sc__product .static__img img {
  display: block;
  width: 100%;
}

.decor-12 {
  position: absolute;
  width: 637px;
  height: 569px;
  background: url("../img/decor-12.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  top: 52px;
  margin-left: -112px;
}

.decor-13 {
  position: absolute;
  width: 1091px;
  height: 924px;
  background: url("../img/decor-13.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  top: -41px;
  margin-left: -461px;
}

.decor-14 {
  position: absolute;
  width: 1091px;
  height: 952px;
  background: url("../img/decor-14.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  top: 27px;
  margin-left: -12px;
}

.sc__met .container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.tabs__custom {
  margin-bottom: 50px;
}
.tabs__custom .nav-tabs {
  border-bottom: 1.5px solid #ededed;
}

.sc__results .tabs__custom {
  margin-bottom: 0 !important;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: none !important;
  border-radius: 0 !important;
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  padding: 0 16px;
  color: #1d1d1b !important;
  background: transparent !important;
  position: relative;
}
.nav-tabs .nav-link:after {
  content: "";
  height: 4px;
  background: #cccccc;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0;
}
.nav-tabs .nav-link:hover:after {
  opacity: 1;
}
.nav-tabs .nav-link.active:after {
  opacity: 1;
  background: #23d1ae;
}

.content__box > .content__box {
  margin-top: 1em;
}

.content__box-title {
  font-weight: bold;
  font-size: 1.75em;
  line-height: 1.14;
  color: #000000;
}

.content__box-title small {
  opacity: 0.6;
  font-size: 0.6em;
  font-weight: inherit;
  margin-left: 0.6em;
}

.content__box-description {
  margin: 0.5em 0 1em;
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.14;
  color: #000000;
}

.content__box-description ~ .docs__list {
  margin-top: 0 !important;
}

.docs__item-img {
  width: 44px;
  min-width: 44px;
  margin-right: 16px;
  position: relative;
}
.docs__item-img:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  box-shadow: -5px 5px 18px rgba(35, 209, 174, 0.3);
  right: 0;
  bottom: 0;
}
.docs__item-img img {
  display: block;
  width: 100%;
}

.docs__item-title {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
}

.link__download {
  font-size: 16px;
  line-height: 20px;
  color: #00a88e;
}
.link__download:hover {
  color: #23d1ae !important;
}
.link__download:hover path {
  fill: #23d1ae !important;
}
.link__download:hover .icon__download {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
}

.icon__download {
  width: 16px;
  position: relative;
  top: 2px;
  height: 16px;
}
.icon__download svg {
  display: block;
  width: 100%;
  height: 100%;
}

.content__box:not(:last-child) {
  margin-bottom: 48px;
}

.docs__list:not(:first-child) {
  margin-top: 3em;
}

.docs__list .row {
  margin-bottom: -32px;
}

.docs__item {
  margin-bottom: 32px;
  position: "relative";
}

.docs__item .link__download:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sc__search {
  background: #00a88e;
}
.sc__search .container {
  padding-top: 180px;
  padding-bottom: 78px;
}
.sc__search .search__box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 0;
  height: 64px;
  opacity: 1;
  visibility: visible;
}
.sc__search .search__submit {
  position: absolute;
  left: 22px;
  top: 50%;
  margin-top: -10px;
}
.sc__search .search__submit path {
  fill: #00a88e;
}
.sc__search .search__submit:hover path {
  fill: #23d1ae !important;
}
.sc__search .search__field input {
  background: #fff;
  color: #444444;
  padding-left: 64px;
}
.sc__search .search__field input::-webkit-input-placeholder {
  color: #444444;
}
.sc__search .search__field input:-moz-placeholder {
  color: #444444;
}
.sc__search .search__field input::-moz-placeholder {
  color: #444444;
}
.sc__search .search__field input:-ms-input-placeholder {
  color: #444444;
}
.sc__search .search__close path {
  fill: #aeaeae;
}
.sc__search .search__close:hover path {
  fill: #1d1d1d;
}

.results__wrap {
  overflow: hidden;
}
.results__wrap .tabs__custom .nav-tabs {
  border-bottom: none;
  position: relative;
}
.results__wrap .tabs__custom .nav-tabs:before {
  content: "";
  bottom: 0;
  left: -2000px;
  height: 1.5px;
  background: #ededed;
  position: absolute;
  right: -2000px;
}

.results__list {
  margin-top: 50px;
}

.results__item:not(:last-child) {
  margin-bottom: 32px;
}

.results__item-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #00a88e;
  margin-bottom: 8px;
}
.results__item-title a {
  color: #00a88e !important;
}
.results__item-title a:hover {
  color: #23d1ae !important;
}

.results__item-descr {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #999999;
}

.results__list {
  margin-bottom: 64px;
}

.page-link {
  width: 32px !important;
  height: 32px !important;
  background: #f2f2f2;
  border-radius: 3px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  padding: 0 !important;
  border: none !important;
  color: #444444 !important;
  box-shadow: none !important;
}
.page-link.active {
  color: #fff !important;
  background: #00a88e;
}

.page-item:not(:last-child) {
  margin-right: 8px;
}

.sc__results {
  position: relative;
}
.sc__results .container {
  padding-bottom: 120px;
}

.sc__search {
  position: relative;
  overflow: hidden;
}

.decor-15 {
  background: url("../img/decor-15.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  position: absolute;
  margin-left: -616px;
  width: 857px;
  height: 896px;
  top: -460px;
}

.pagination {
  margin-bottom: 0;
}

.sc__advants {
  background: #f6fbfd;
}
.sc__advants .container {
  padding-top: 32px;
  padding-bottom: 32px;
}

.advants__item-icon {
  height: 88px;
  margin-bottom: 16px;
}
.advants__item-icon img {
  display: block;
  margin: 0 auto;
}

.advants__item-text {
  font-size: 21px;
  color: #1d1d1b;
  text-align: center;
  line-height: 24px;
}

.info__item-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 24px;
}

.info__item-content {
  max-width: 481px;
}

.info__item-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
.info__item-text p {
  margin: 0 0 16px 0;
}
.info__item-text > *:last-child {
  margin-bottom: 0 !important;
}

.info__item:nth-child(1) .static__img {
  width: 378px;
}
.info__item:nth-child(2) .static__img {
  width: 475px;
}
.info__item:nth-child(3) .static__img {
  width: 362px;
}
.info__item:nth-child(2n) .info__item-img {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.info__item:nth-child(2n) .info__item-content {
  margin-left: 20px;
}
.info__item .static__img {
  margin-left: 50px;
}
.info__item .static__img img {
  display: block;
  width: 100%;
}

.info__item:not(:last-child) {
  margin-bottom: 75px;
}

.sc__info .container {
  padding-bottom: 120px;
}

.sc__redirect {
  background: linear-gradient(
    91.86deg,
    #f3f7be 0.35%,
    #d7f6cd 40.65%,
    #b8e7f1 99.55%
  );
}
.sc__redirect .container {
  padding-top: 62px;
  padding-bottom: 62px;
}
.sc__redirect .sc__header {
  margin-left: -32px;
  max-width: 460px;
  margin-bottom: 0;
}
.sc__redirect .sc__title {
  margin-bottom: 32px;
}
.sc__redirect .container {
  position: relative;
}
.sc__redirect .static__img {
  width: 505px;
  position: absolute;
  bottom: 0;
  margin-left: -20px;
  left: 50%;
}
.sc__redirect .static__img img {
  display: block;
  width: 100%;
}

.sc__compare .container {
  padding-top: 120px;
  padding-bottom: 120px;
}
.sc__compare .sc__header {
  margin-bottom: 56px;
}

.tb__label {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #666666;
}

.compare__head-info-icon {
  min-width: 64px;
  width: 64px;
  margin-right: 24px;
}
.compare__head-info-icon img {
  display: block;
}

.compare__head-info-title {
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #1d1d1b;
}
.compare__head-info-title span {
  color: #00a88e;
}

.compare__head-info {
  padding: 12px 32px 18px;
}

.compare__box {
  position: relative;
  margin-bottom: 120px;
}
.compare__box:before {
  background: #c4c4c4;
  top: 0;
  left: 33.3333%;
  bottom: -40px;
  content: "";
  position: absolute;
  width: 1px;
}

.recommend__decor {
  border: 1px solid #23d1ae;
  bottom: -40px;
  right: 0;
  width: 33.3333%;
  top: 0;
  position: absolute;
}

.compare__data .td {
  padding: 16px;
}

.compare__item-icon {
  width: 36px;
  min-width: 36px;
  margin-right: 12px;
}

.compare__item-icon img {
  display: block;
  width: 100%;
}

/* .compare__item-content {
  padding-top: 10px;
} */

.compare__item-title {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.compare__item-descr {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
}

.compare__row:nth-child(2n) {
  background: #f8f8f8;
}

.icon__yes {
  background: url("../img/icon-yes.svg") no-repeat center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon__no {
  background: url("../img/icon-no.svg") no-repeat center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.recommend__title {
  position: absolute;
  bottom: 100%;
  left: -1px;
  right: -1px;
  height: 24px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: #23d1ae;
}

.compare__item-descr a {
  color: #23d1ae !important;
  border-bottom: 1px solid #23d1ae;
}
.compare__item-descr a:hover {
  border-bottom: 1px solid transparent;
}

.compare__item-title {
  padding-right: 16px;
}

.compare__head {
  background: transparent !important;
}
.compare__head .actions__wrap {
  position: relative;
  z-index: 4;
  padding: 0 32px;
}
.compare__head .actions__wrap .btn__border,
.compare__head .actions__wrap .btn__custom {
  width: 100%;
}

.btn__border-green {
  color: #00a88e;
  border-color: #00a88e;
}

.compare__hint {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: rgba(102, 102, 102, 0.72);
  margin-bottom: 64px;
}
.compare__hint p {
  margin: 0 0 16px 0;
}
.compare__hint > *:last-child {
  margin-bottom: 0 !important;
}

.loading__item:not(:last-child) {
  margin-right: 32px;
}

.app__store-link {
  width: 144px;
}
.app__store-link:hover {
  opacity: 0.75;
}

.play__store-link {
  width: 162px;
}
.play__store-link:hover {
  opacity: 0.75;
}

.link__icon {
  font-size: 18px;
  line-height: 24px;
  color: #00a88e !important;
}
.link__icon span {
  margin-right: 8px;
}
.link__icon:hover {
  color: #23d1ae !important;
}
.link__icon:hover path {
  fill: #23d1ae;
}

.icon__link {
  width: 20px;
}
.icon__link svg {
  width: 100%;
  height: 100%;
  display: block;
}

.instructions__item-box {
  padding: 24px;
  box-shadow: 0px 6px 24px rgba(38, 202, 153, 0.2);
  position: relative;
}
.instructions__item-box .actions__wrap {
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.instructions__item-content {
  width: 50%;
  min-width: 50%;
}

.instructions__item-title {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
}

.instructions__item-descr {
  font-size: 14px;
  line-height: 18px;
}

.instructions__item-img {
  width: 240px;
  min-width: 240px;
  margin-top: -24px;
  margin-bottom: -24px;
}
.instructions__item-img img {
  display: block;
  width: 100%;
}

.loading__box {
  margin-bottom: 80px;
}

.masonry-list .row {
  margin-left: -1px;
  margin-right: -1px;
}
.masonry-list .col__custom {
  padding-right: 1px;
  padding-left: 1px;
}

.articles__list .row {
  margin-bottom: -2px;
}

.article__item {
  margin-bottom: 2px;
}

.sc__articles .actions__wrap {
  position: relative;
  z-index: 5;
}

.btn__border {
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
}

.promo__slider-box .slick-arrow {
  display: none !important;
}

.media__slider-box {
  position: relative;
  margin: 0 0 48px 0;
}
.media__slider-box .arrow.right {
  right: 20px;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.media__slider-box .arrow.left {
  left: 20px;
}
.media__slider-box .arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  z-index: 10;
  background: transparent !important;
}
.media__slider-box .arrow:hover path {
  fill: #d93d4e;
}
.media__slider-box .slick-dots {
  list-style: none;
  position: absolute;
  bottom: 35px;
  margin: 0 !important;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0;
}
.media__slider-box .slick-dots li {
  padding-left: 0;
  font-size: 0;
  margin-bottom: 0 !important;
  display: inline-block;
  vertical-align: top;
}
.media__slider-box .slick-dots li:before {
  display: none;
}
.media__slider-box .slick-dots li:not(:last-child) {
  margin-right: 16px;
}
.media__slider-box .slick-dots li button {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  border: none;
}
.media__slider-box .slick-dots li.slick-active button {
  background: #f83c52;
}

.mode__nav ul li a {
  cursor: pointer;
}

.media__slide {
  height: 568px;
}
.media__slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 !important;
}

.page {
  position: relative;
  min-height: 100vh;
}

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
}

.sc__articles.first-section .container {
  padding-top: 120px;
}

.search__field.error-field input {
  border-bottom: 2px solid #ff0000 !important;
}

.icon__decor {
  position: absolute;
  width: 156px;
  height: 156px;
  z-index: 10;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon__decor.icon__alien {
  background-image: url("../img/alien.svg");
}
.icon__decor.icon__alt {
  background-image: url("../img/alt.svg");
}
.icon__decor.icon__cursors {
  background-image: url("../img/cursors.svg");
}
.icon__decor.icon__emoji {
  background-image: url("../img/emoji.svg");
}
.icon__decor.icon__eye {
  background-image: url("../img/eye.svg");
}
.icon__decor.icon__eye2 {
  background-image: url("../img/eye2.svg");
}
.icon__decor.icon__hashtag {
  background-image: url("../img/hashtag.svg");
}
.icon__decor.icon__keyhole {
  background-image: url("../img/keyhole.svg");
}
.icon__decor.icon__light {
  background-image: url("../img/light.svg");
}
.icon__decor.icon__planet {
  background-image: url("../img/planet.svg");
}
.icon__decor.icon__rainbow {
  background-image: url("../img/rainbow.svg");
}
.icon__decor.icon__stars {
  background-image: url("../img/stars.svg");
}

.sc__promo .icon__keyhole {
  width: 264px;
  height: 264px;
  left: 50%;
  margin-left: 221px;
  top: 252px;
}

.sc__articles .icon__light {
  width: 156px;
  height: 156px;
  left: 210px;
  top: 32px;
}
.sc__articles .icon__eye2 {
  width: 208px;
  height: 208px;
  right: 161px;
  top: 158px;
}
.sc__articles .icon__eye {
  width: 264px;
  height: 264px;
  left: 32px;
  top: 32px;
}
.sc__articles .icon__planet {
  width: 208px;
  height: 208px;
  left: 32px;
  top: 160px;
}
.sc__articles .icon__cursors {
  width: 208px;
  height: 208px;
  right: 32px;
  bottom: 32px;
}
.sc__articles .icon__emoji {
  width: 156px;
  height: 156px;
  right: 32px;
  top: 32px;
}

.articles__list {
  position: relative;
}

.article__item-1 .article__item-title {
  max-width: 500px;
}

.articles__list-title {
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -0.03em;
  margin-bottom: 32px;
}

.footer__top .container {
  padding-top: 48px;
  padding-bottom: 48px;
}

.subscribe__box-title {
  margin-bottom: 32px;
  font-size: 21px;
  line-height: 24px;
}

.subscribe__btn {
  cursor: pointer;
  min-width: 160px;
  width: 160px;
  color: #fff !important;
  font-size: 16px;
  line-height: 20px;
  background: #00a88e;
  border-radius: 3px;
  margin-left: 16px;
  height: 48px;
}
.subscribe__btn:hover {
  background: #23d1ae;
  box-shadow: 0px 12px 40px rgba(21, 190, 164, 0.48);
}
.subscribe__btn:focus {
  background: #00a88e;
  box-shadow: none;
}

.subscribe__form {
  margin-bottom: 32px;
}

.subscribe__field {
  width: 100%;
}
.subscribe__field input {
  display: block;
  width: 100%;
  height: 48px;
  border: 1.5px solid #cccccc;
  padding: 0 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
  color: #000;
}
.subscribe__field input:focus {
  border: 1.5px solid #222222;
}
.subscribe__field input::-webkit-input-placeholder {
  color: #999999;
}
.subscribe__field input:-moz-placeholder {
  color: #999999;
}
.subscribe__field input::-moz-placeholder {
  color: #999999;
}
.subscribe__field input:-ms-input-placeholder {
  color: #999999;
}

.checkbox__wrap label {
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: flex-start;
  position: relative;
}
.checkbox__wrap label input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.checkbox__wrap label input:checked ~ .checkbox__decor {
  border: 2px solid #00a88e;
  background: #00a88e;
}
.checkbox__wrap label input:checked ~ .checkbox__decor:before {
  opacity: 1;
}

.checkbox__title {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
}

.subscribe__field,
.checkbox__wrap {
  position: relative;
}

.checkbox__decor {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: transparent;
  border-radius: 3px;
  position: relative;
  min-width: 24px;
  border: 2px solid #cccccc;
}
.checkbox__decor:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: url("../img/checked.svg") no-repeat center;
  background-size: contain;
  width: 18px;
  height: 12px;
  margin-top: -6px;
  margin-left: -9px;
  opacity: 0;
}

.footer__bottom .container {
  padding-bottom: 28px;
}

.error__message {
  position: absolute;
  left: 0;
  top: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  background: #ff3748;
  padding: 5px 7px;
}

.checkbox__wrap .error__message {
  margin-top: 10px;
}

.checkbox__decor {
  cursor: pointer;
}

.page__product .mode__nav {
  display: none;
}
.page__product .search__toggle {
  margin-right: 0;
}

.arrow__up {
  position: fixed;
  width: 56px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.15);
  border-radius: 3px;
  z-index: 10;
  margin-left: 545px;
  left: 50%;
  cursor: pointer;
  bottom: 260px;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
}
.arrow__up.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.compare__head-info-price {
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #1d1d1b;
  margin-top: 5px;
  margin-bottom: 5px;
}

.compare__head-info-height {
  min-height: 148px;
}

.compare__head-info-price {
  min-height: 36px;
}

.product__price-box {
  margin-bottom: 20px;
  color: #fff;
}

.product__price {
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
}

.product__price-old {
  text-decoration: line-through !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-left: 16px;
}

.mobile__nav-box .mode__nav {
  display: block !important;
}
.mobile__nav-box .mode__nav ul li {
  width: 50%;
}
.mobile__nav-box .mode__nav ul li a {
  border-color: #00a88e;
}
.mobile__nav-box .mode__nav ul li.active a {
  background: #00a88e;
  color: #fff;
}

.modal__custom {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5000;
  padding: 35px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}
.modal__custom.active {
  opacity: 1;
  visibility: visible;
}

.modal__box {
  max-height: 100%;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.24);
  width: 800px;
  margin: 0 auto;
  padding: 64px 32px 32px;
}
.modal__box .tab-content {
  padding-top: 40px;
}

.modal__box-title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #1d1d1b;
  text-align: center;
}

.tab__step:not(:last-child) {
  margin-bottom: 32px;
}

.modal__box {
  position: relative;
}
.modal__box .tabs__custom {
  margin-bottom: 0;
}

.close__modal {
  width: 20px;
  height: 20px;
  right: 16px;
  top: 16px;
  cursor: pointer;
  z-index: 4;
  position: absolute;
}
.close__modal:hover path {
  fill: #000;
}
.close__modal svg {
  width: 100%;
  display: block;
  height: 100%;
}

.tab__step-numb {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  background: #23d1ae;
  margin-right: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.tab__step-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #1d1d1b;
  width: 100%;
  padding-top: 4px;
}
.tab__step-text a {
  color: #00a88e !important;
  border-bottom: 1px solid transparent;
}
.tab__step-text a:hover {
  border-bottom: 1px solid #00a88e;
}

.close__modal,
.link__download {
  cursor: pointer;
}

.tab__step-text p {
  margin: 0 0 24px 0;
}
.tab__step-text ul {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
}
.tab__step-text ul li {
  position: relative;
  display: block;
  padding-left: 36px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #444444;
}
.tab__step-text ul li:before {
  content: "";
  top: 0;
  left: 0;
  background: url("../img/checked-icon.svg") no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
}
.tab__step-text ul li:not(:last-child) {
  margin-bottom: 16px;
}

.quiz-preview-title {
  margin-left: -4px !important;
}

.quiz-preview-wrap .quiz-preview-title {
  margin-right: -150px;
}

.sc__interactives .container {
  padding-top: 125px;
}

.quiz-result-value-number {
  margin-bottom: 35px;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
}

.quiz-result-value {
  margin-bottom: 4px;
}

.quiz__test4 .quiz-preview-title {
  font-size: 50px;
  margin-right: -100px;
}
.quiz__test4 .quiz-preview-img {
  margin-top: 92px !important;
  margin-right: -82px;
}

.quiz__test5 .quiz-preview-title {
  margin-right: -40px;
}
.quiz__test5 .quiz-preview-img {
  margin-top: 76px !important;
  margin-right: -82px;
}

.quiz__test6 .quiz-preview-title {
  margin-right: -40px;
}
.quiz__test6 .quiz-preview-img {
  margin-top: 93px !important;
  margin-right: -82px;
}
.quiz__test6 .quiz-preview-text {
  max-width: 435px;
}

a,
input,
textarea,
button,
.search__toggle path,
.search__submit path,
.search__close path,
.search__box,
.main__nav,
.search__toggle,
.slide__nav path,
.icon__arrow path,
.social path,
.nav-link:after,
.link__download path,
.link__icon path,
.btn__border,
.nav-link,
.arrow path,
.icon__download,
.mobile__nav-box,
.checkbox__decor:before,
.subscribe__btn,
.arrow__up,
.modal__custom,
.close__modal path {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}
@media screen and (max-height: 760px) and (min-width: 1200px) {
  .promo__slider-item {
    height: 100vh;
  }

  .promo__img {
    width: 105.26vh;
    margin-left: -34.21vh;
    top: 3.55vh;
  }
}
@media screen and (max-height: 700px) and (min-width: 1200px) {
  .modal__box {
    padding: 30px 32px 30px;
  }

  .tab__step:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 1240px) {
  .arrow__up {
    left: auto;
    margin-left: 0;
    right: 20px;
    bottom: 350px;
  }
}
@media screen and (max-width: 1230px) and (min-width: 1199px) {
  .slide__navs {
    right: 15px;
    margin-right: 0;
  }
}
@media screen and (max-width: 1199px) {
  .promo__title {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 52px;
  }

  .promo__descr {
    max-width: 280px;
    font-size: 14px;
    line-height: 18px;
  }

  .promo__content .actions__wrap {
    margin-top: 32px;
  }

  .promo__slider-item {
    height: 620px;
  }

  .promo__img {
    width: 620px;
    margin-left: -230px;
    top: 27px;
  }

  .slide__navs {
    margin-right: -470px;
  }

  .article__item-title {
    font-size: 24px;
    line-height: 30px;
  }

  .row__custom {
    margin-left: -12px;
    margin-right: -12px;
  }

  .article__item-category {
    margin-bottom: 16px;
  }

  .article__item-1 .article__item-block {
    height: 276px;
  }

  .article__item-content {
    padding: 16px;
  }

  .col__custom {
    padding-left: 12px;
    padding-right: 12px;
  }

  .article__item-2 .article__item-block {
    height: 476px;
  }

  .article__item-3 .article__item-block {
    height: 476px;
  }

  .article__item-decor {
    width: 301px;
    margin-left: -15px;
  }

  .article__item-4 .article__item-block {
    height: 276px;
  }

  .article__item-5 .article__item-block {
    height: 276px;
  }

  .footer__logo {
    width: 135px;
  }

  .sc__product-front-small .sc__title {
    font-size: 48px;
    line-height: 52px;
  }

  .info__item .static__img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .instructions__item-img {
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
  }

  .instructions__item-content {
    padding-bottom: 32px;
  }

  .promo__content {
    max-width: 590px;
  }

  .sc__promo .icon__keyhole {
    width: 208px;
    height: 208px;
    top: 164px;
  }

  .sc__articles .icon__light {
    width: 106px;
    height: 106px;
    left: 55px;
    top: 10px;
  }
  .sc__articles .icon__eye2 {
    width: 128px;
    height: 128px;
    right: 60px;
    top: 20px;
  }
  .sc__articles .icon__planet {
    width: 128px;
    height: 128px;
  }
  .sc__articles .icon__cursors {
    width: 128px;
    height: 128px;
    right: 32px;
    bottom: 32px;
  }
  .sc__articles .icon__emoji {
    width: 106px;
    height: 106px;
    right: 10px;
    bottom: 138px;
  }
  .sc__articles .icon__eye {
    width: 186px;
    height: 186px;
    left: 24px;
    top: 24px;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .container {
    max-width: 606px;
  }
}
@media screen and (min-width: 992px) {
  .nav__bars {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .header {
    height: 96px;
  }

  .logo {
    width: 71px;
  }

  .main__nav {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .promo__slider-item {
    height: 576px;
  }

  .promo__img {
    width: 440px;
    margin-left: -120px;
    top: 70px;
  }

  .promo__slider-item .container {
    padding-bottom: 48px;
  }

  .promo__content .btn__custom {
    width: 276px;
  }

  .slide__navs {
    margin-right: 0;
    right: auto;
    left: 50%;
    margin-left: -288px;
    bottom: auto;
    top: 164px;
  }

  .slide__info {
    right: auto;
    bottom: auto;
    font-size: 14px;
    line-height: 18px;
    left: 100%;
    margin-left: 8px;
    top: 8px;
  }

  .slide__nav {
    width: 48px;
    height: 48px;
  }

  .sc__articles .container {
    padding-top: 18px;
  }

  .article__item-category {
    margin-bottom: 8px;
  }

  .article__item-title {
    font-size: 21px;
    line-height: 24px;
  }

  .article__item-1 .article__item-block {
    height: 176px;
  }

  .article__item-2 .article__item-block {
    height: 307px;
  }

  .article__item-3 .article__item-block {
    height: 307px;
  }
  .article__item-3 .article__item-title {
    font-size: 21px;
    line-height: 24px;
  }

  .article__item-4 .article__item-block {
    height: 176px;
  }

  .article__item-5 .article__item-block {
    height: 176px;
    padding-left: 50%;
  }
  .article__item-5 .article__item-title {
    font-size: 21px;
    line-height: 24px;
  }

  .sc__articles .actions__wrap {
    margin-top: 32px;
  }

  .sc__articles .btn__border {
    width: 276px;
  }

  .article__item-decor {
    width: 205px;
    margin-left: 49px;
    top: 11px;
    left: 0;
  }

  .sc__product-front .container {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .sc__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: normal;
  }

  .sc__header {
    margin-bottom: 24px;
  }

  .icon__item-img {
    margin-right: 16px;
  }

  .icon__item-descr {
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
  }
  .icon__item-descr br {
    display: none;
  }

  .icon__item:not(:last-child) {
    margin-bottom: 24px;
  }

  .icon__item-img {
    margin-top: -3px;
  }

  .icon__list {
    margin-bottom: 32px;
  }

  .quiz-container {
    min-width: 100% !important;
  }

  .quiz-header-title {
    font-size: 16px;
  }

  .sc__product-front .static__img {
    width: 625px;
    margin-left: -462px;
  }
  .sc__product-front .sc__content {
    position: relative;
    z-index: 2;
  }

  .footer .container {
    flex-wrap: wrap;
  }

  .footer__info {
    width: 100%;
  }
  .footer__info li:nth-child(1) {
    display: block !important;
  }

  .footer {
    height: auto;
    margin: 0;
  }
  .footer .container {
    padding-top: 27px;
    padding-bottom: 21px;
    position: relative;
  }
  .footer .social {
    position: absolute;
    right: 15px;
    top: 31px;
  }

  .sc__product-front .btn__custom {
    width: 100%;
  }

  .footer__logo {
    width: 156px;
    margin-bottom: 16px;
  }

  .nav__bars {
    width: 20px;
    height: 20px;
    margin-left: 32px;
  }
  .nav__bars svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .decor-3 {
    background: url(../img/decor-3.svg) no-repeat;
    background-size: contain;
    width: 389px;
    margin-left: -504px;
    top: -124px;
    left: 50%;
    height: 398px;
  }

  .decor-4 {
    background: url(../img/decor-4.svg) no-repeat;
    background-size: contain;
    width: 389px;
    margin-left: -484px;
    bottom: 10px;
    left: 50%;
    height: 398px;
  }

  .decor-5 {
    background: url(../img/decor-5.svg) no-repeat;
    background-size: contain;
    width: 389px;
    margin-left: 37px;
    bottom: 151px;
    left: 50%;
    height: 398px;
  }

  .article__title {
    margin-bottom: 32px;
    font-size: 52px;
    line-height: 52px;
  }

  .article__info {
    font-size: 12px;
    line-height: 18px;
  }

  .article__block-descr {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }

  .article__description {
    font-size: 18px;
    line-height: 32px;
  }

  .article__text h3 {
    font-size: 36px;
    line-height: 42px;
    margin: 80px 0 32px 0;
  }
  .article__text p {
    margin: 0 0 24px 0;
    font-size: 16px;
    line-height: 24px;
  }
  .article__text img {
    margin: 32px auto 48px;
  }
  .article__text h4 {
    font-size: 24px;
    line-height: 28px;
    margin: 32px 0 20px 0;
  }

  .redirect__box-img {
    width: 120px;
    min-width: 120px;
    margin-right: 36px;
  }

  .redirect__box-title {
    font-size: 24px;
    line-height: 28px;
  }

  .redirect__box-descr {
    font-size: 16px;
    line-height: 24px;
  }

  .article__text-box-indent {
    margin-top: 80px;
  }

  .steps__item-content {
    max-width: 100%;
  }

  .steps__item {
    margin-bottom: 32px;
  }

  .steps__list .row {
    margin-bottom: -32px;
  }

  .sc__other .sc__title {
    font-size: 32px;
    line-height: 36px;
  }

  .sc__descr {
    font-size: 19px;
    line-height: 22px;
  }

  .sc__product-front-small .sc__header {
    position: relative;
  }

  .advants__item-text {
    font-size: 17px;
    line-height: 20px;
  }

  .advants__item-icon {
    height: 60px;
  }
  .advants__item-icon img {
    width: 60px;
  }

  .info__item-content {
    max-width: 100%;
    margin-top: 32px;
  }

  .info__item-img {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .sc__redirect .static__img {
    width: 406px;
  }

  .loading__box {
    flex-wrap: wrap;
  }

  .loading__item {
    width: 100%;
  }
  .loading__item:not(:last-child) {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .instructions__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .hidden-md-down {
    display: none !important;
  }

  .compare__box:before {
    display: none;
  }

  .compare__mb-head-box {
    position: relative;
    padding: 8px;
  }

  .compare__mb-head-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1d1d1b;
  }
  .compare__mb-head-title span {
    color: #00a88e;
  }

  .compare__mb-head {
    margin-bottom: 8px;
  }

  .compare__mb-head-main {
    position: relative;
    border: 1px solid #23d1ae;
  }

  .compare__mb-row {
    padding: 16px;
    margin: 0 -16px;
  }
  .compare__mb-row:nth-child(2n + 1) {
    background: #f8f8f8;
  }

  .compare__item-title {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .compare__item-descr {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }

  .compare__mb-head .actions__wrap {
    padding-top: 8px;
  }
  .compare__mb-head .actions__wrap .btn__border,
  .compare__mb-head .actions__wrap .btn__custom {
    width: 100%;
  }

  .article__item-4 .article__item-title {
    font-size: 16px;
    line-height: 20px;
  }

  .search__box {
    margin-top: -24px;
    height: 48px;
  }

  .search__field input {
    height: 48px;
  }

  .search__box {
    left: -100px;
    right: 42px;
  }

  .search__close {
    width: 48px;
    height: 48px;
  }

  .sc__promo .icon__keyhole {
    width: 156px;
    height: 156px;
    top: 225px;
    margin-left: 188px;
  }

  .sc__articles .icon__light {
    width: 66px;
    height: 66px;
    left: 16px;
    top: 16px;
  }
  .sc__articles .icon__eye2 {
    width: 88px;
    height: 88px;
    right: 16px;
    top: 16px;
  }
  .sc__articles .icon__planet {
    width: 92px;
    height: 92px;
    right: 80px;
    top: 32px;
  }
  .sc__articles .icon__emoji {
    width: 68px;
    height: 68px;
    right: 16px;
    top: 16px;
  }
  .sc__articles .icon__cursors {
    width: 98px;
    height: 98px;
    right: 16px;
    bottom: 16px;
  }
  .sc__articles .icon__eye {
    width: 136px;
    height: 136px;
    left: 16px;
    top: 16px;
  }

  .mobile__nav-box {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 300;
    opacity: 0;
    visibility: hidden;
  }
  .mobile__nav-box.active {
    opacity: 1;
    visibility: visible;
  }
  .mobile__nav-box .search__box {
    opacity: 1;
    height: auto;
    right: 0;
    visibility: visible;
    position: relative;
    background: #f8f8f8;
    left: 0;
    top: 0;
    margin: 0;
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .mobile__nav-box .search__box:before {
    background: #f8f8f8;
    left: 100%;
    width: 1000px;
    top: 0;
    height: 100%;
    position: absolute;
    content: "";
  }
  .mobile__nav-box .search__box:after {
    background: #f8f8f8;
    right: 100%;
    width: 1000px;
    top: 0;
    height: 100%;
    position: absolute;
    content: "";
  }
  .mobile__nav-box .search__field.error-field input {
    border-bottom: 2px solid #ff0000 !important;
  }
  .mobile__nav-box .search__close {
    display: none !important;
  }
  .mobile__nav-box .search__submit {
    margin-right: 8px;
  }
  .mobile__nav-box .search__submit path {
    fill: #00a88e;
  }
  .mobile__nav-box .search__field input {
    border-bottom: 2px solid #00a88e !important;
  }

  .mobile__nav-header {
    height: 80px;
  }

  .close__nav {
    width: 20px;
    height: 20px;
    position: relative;
    background: url("../img/close.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
  .close__nav:before {
    content: "";
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
  }

  .mobile__nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .mobile__nav ul li {
    display: block;
  }
  .mobile__nav ul li:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
  .mobile__nav ul li a {
    display: flex;
    height: 48px;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #1d1d1b !important;
  }
  .mobile__nav ul li.active a {
    color: #00a88e !important;
  }

  .arrow__up {
    right: 20px;
    bottom: 10px;
  }

  .quiz-content {
    height: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }

  .mobile__nav-box {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  html,
  body,
  .wrapper {
    min-height: 0;
    height: auto;
  }

  .subscribe__btn {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }

  .content {
    padding-bottom: 0;
  }

  .footer {
    height: auto;
    margin-top: 0;
    position: relative;
  }

  .sc__promo .icon__keyhole {
    width: 124px;
    height: 124px;
    top: 88px;
    margin-left: 0;
    left: 20px;
  }

  .sc__articles .icon__cursors {
    width: 68px;
    height: 68px;
    right: 8px;
    bottom: 64px;
  }
  .sc__articles .icon__planet {
    width: 80px;
    height: 80px;
    right: 8px;
    top: 16px;
  }
  .sc__articles .icon__eye2 {
    width: 88px;
    height: 88px;
    right: 8px;
    top: 16px;
  }
  .sc__articles .icon__eye {
    width: 106px;
    height: 106px;
    left: 8px;
    top: 8px;
  }
}
@media screen and (max-width: 767px) {
  /* .sc__common .tabs__custom .nav-tabs .nav-item {
    width: 50%;
    min-width: 175px;
  } */

  .mobile__nav-box {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .mobile__nav-box.active {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  .quiz-preview-title {
    margin-left: -1px !important;
  }

  .sc__advants .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .sc__product-front-small .icon__list,
  .sc__product-front-small .actions__wrap {
    display: none !important;
  }

  .advants__item {
    display: flex;
    align-items: center;
  }
  .advants__item:not(:last-child) {
    margin-bottom: 32px;
  }

  .advants__item-icon {
    height: 44px;
    width: 44px;
    min-width: 44px;
    margin-bottom: 0;
    margin-right: 12px;
  }
  .advants__item-icon img {
    width: 44px;
  }

  .advants__item-text {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  .sc__info .container {
    padding-bottom: 64px;
    padding-top: 48px;
  }

  .info__item:nth-child(3) .static__img {
    width: 245px;
  }

  .info__item:nth-child(1) .static__img {
    width: 253px;
  }

  .info__item-content {
    margin-left: 0 !important;
  }

  .info__item-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 32px;
  }

  .info__item:nth-child(2) .static__img {
    width: 300px;
  }

  .info__item-text {
    font-size: 18px;
    line-height: 24px;
  }
  .info__item-text p {
    margin: 0 0 24px 0;
  }

  .sc__redirect .container {
    padding-top: 48px;
    padding-bottom: 0;
  }

  .sc__redirect .btn__custom {
    width: 100%;
  }

  .sc__redirect .static__img {
    width: 390px;
    position: relative;
    margin-top: 51px;
    left: 50%;
    margin-left: -195px;
  }

  .sc__redirect .sc__header {
    margin-left: 0;
    max-width: 100%;
  }

  .loading__box {
    margin-bottom: 64px;
  }

  .sc__compare .container {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .compare__hint {
    margin-bottom: 48px;
  }

  .compare__box {
    margin-bottom: 64px;
  }

  .instructions__item-content {
    width: 100%;
    padding-bottom: 0;
    min-width: 100%;
  }

  .instructions__item-title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }

  .instructions__item-descr {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
  }

  .instructions__item-img {
    width: 130px;
    min-width: 130px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }

  .instructions__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .instructions__item-box {
    padding: 130px 24px 24px;
    display: block !important;
  }

  .instructions__item-box .actions__wrap {
    position: relative;
    bottom: 0;
    left: 0;
  }

  .articles__list-title {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .hidden-sm-down {
    display: none !important;
  }

  .quiz-bg:not(:first-child) {
    padding-top: 50px;
  }

  .quiz-question-answers.net-mode .quiz-question-answer {
    height: 105px;
  }

  .tab__step-text {
    font-size: 16px;
    line-height: 24px;
  }

  .tab__step:not(:last-child) {
    margin-bottom: 20px;
  }

  .modal__box .tab-content {
    padding-top: 34px;
  }

  .quiz__answer-img {
    width: 105px;
    margin-left: 40px;
    min-width: 105px;
  }

  .modal__box-title {
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 24px;
  }

  .modal__custom {
    padding: 0;
    display: block !important;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }

  .tab__step-text ul {
    margin: 0 0 16px 0;
  }
  .tab__step-text p {
    margin: 0 0 16px 0;
  }

  .modal__box {
    max-height: 1000%;
    width: 100%;
    padding: 52px 16px 32px;
  }

  .tab__step-text ul li:not(:last-child) {
    margin-bottom: 12px;
  }

  .tab__step-text ul li {
    font-size: 14px;
    line-height: 18px;
  }

  .quiz__test4 .quiz-preview-title {
    font-size: 34px;
    margin-right: 0;
  }

  .quiz__test5 .quiz-preview-title {
    margin-right: 0;
  }

  .quiz-question-answer {
    min-height: 70px !important;
  }
}
@media screen and (max-width: 639px) {
  .search__toggle,
  .mode__nav {
    display: none !important;
  }

  .container {
    width: 100%;
    max-width: 100%;
  }

  .header {
    height: 80px;
  }

  .container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .promo__slider-item {
    height: 672px;
  }

  .promo__title {
    font-size: 32px;
    line-height: 36px;
  }

  .promo__content {
    max-width: 100%;
    width: 100%;
  }

  .promo__content .btn__custom {
    width: 100%;
  }

  .slide__navs {
    margin-left: 0;
    left: 16px;
    top: 304px;
  }

  .sc__articles .container {
    padding-top: 48px;
  }

  .row__custom {
    margin-left: -8px;
    margin-right: -8px;
  }

  .col__custom {
    padding-left: 8px;
    padding-right: 8px;
  }

  .article__item-content {
    padding: 16px 8px;
  }

  .article__item {
    margin-bottom: 16px;
  }

  .article__item-1 .article__item-block,
  .article__item-2 .article__item-block {
    height: 277px;
  }

  .article__item-3 .article__item-block {
    height: 277px;
  }
  .article__item-3 .article__item-title br {
    display: none;
  }

  .article__item-4 .article__item-block,
  .article__item-5 .article__item-block {
    height: 277px;
    align-items: flex-start;
    padding-left: 0;
  }
  .article__item-4 .article__item-title,
  .article__item-5 .article__item-title {
    font-size: 16px;
    line-height: 20px;
  }

  .article__item-decor {
    width: 205px;
    margin-left: -33px;
    top: 125px;
  }

  .articles__list .row {
    margin-bottom: -16px;
  }

  .sc__product-front .container {
    padding-top: 48px;
    padding-bottom: 0;
  }

  .sc__product-front .static__img {
    width: 450px;
    margin-left: -225px;
    position: relative;
    left: 50%;
    margin-top: 25px;
  }

  .btn__custom {
    justify-content: center;
  }

  .sc__articles .btn__border {
    width: 100%;
    padding: 0 24px;
    justify-content: space-between !important;
  }

  .footer__logo-box {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .footer__info {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .footer__info ul li {
    width: 100%;
    display: block;
    margin-right: 0 !important;
  }
  .footer__info ul li:after {
    display: none;
  }

  .footer .social {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 16px;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .article__bg-box {
    height: 545px;
  }

  .article__post {
    margin-top: -90px;
  }

  .article__title {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 36px;
  }

  .article__info {
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .article__info-item {
    width: 100%;
  }
  .article__info-item:not(:last-child) {
    margin-bottom: 4px;
  }

  .article__block-descr {
    padding-top: 45px;
    padding-bottom: 45px;
    margin-bottom: 45px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .article__description {
    font-size: 20px;
    line-height: 30px;
  }

  .article__text h3 {
    font-size: 28px;
    line-height: 32px;
    margin: 48px 0 32px 0;
  }
  .article__text img {
    margin: 32px -16px;
    width: calc(100% + 32px);
    max-width: calc(100% + 32px);
  }
  .article__text h4 {
    font-size: 21px;
    line-height: 24px;
    margin: 32px 0 16px 0;
  }

  .redirect__box {
    align-items: flex-start !important;
  }

  .redirect__box-img {
    width: 97px;
    min-width: 97px;
    margin-right: 18px;
  }

  .redirect__box-title {
    font-size: 21px;
    line-height: 24px;
  }

  .article__text-box-indent {
    margin-top: 64px;
  }

  .steps__item-numb {
    margin-top: -3px;
    min-width: 36px;
    width: 36px;
    margin-right: 14px;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: normal;
  }

  .steps__item-content {
    font-size: 16px;
    line-height: 24px;
  }

  .article__post-box .container {
    padding-bottom: 80px;
  }

  .sc__label {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: #fff;
  }

  .sc__other .container {
    padding-top: 48px;
    padding-bottom: 56px;
  }
  .sc__other .sc__header {
    margin-bottom: 24px;
  }

  .sc__descr {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 18px;
  }

  .sc__product-front-small .sc__title {
    font-size: 32px;
    line-height: 36px;
  }

  .decor-10 {
    background: url(../img/decor-11.svg) no-repeat center;
    background-size: cover;
    margin-left: 55px;
    top: auto;
    bottom: 8px;
    width: 360px;
    height: 807px;
  }

  .sc__product .container {
    padding-top: 112px;
    padding-bottom: 0;
  }

  .product__title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  .product__descr {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
  }

  .actions__item:not(:last-child) {
    margin-bottom: 24px;
  }

  .decor-12 {
    display: none !important;
  }

  .sc__product .static__img {
    position: relative;
    width: 400px;
    margin-left: -200px;
    bottom: 0;
    margin-top: 28px;
    left: 50%;
    z-index: 2;
  }

  .sc__met .container {
    padding-top: 48px;
    padding-bottom: 64px;
  }
  .sc__met .sc__title {
    font-size: 28px;
    line-height: 32px;
  }
  .sc__met .sc__header {
    margin-bottom: 16px;
  }

  .tabs__custom .nav-tabs {
    border-bottom: 1.5px solid #ededed;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: -16px;
    margin-left: -16px;
  }
  .tabs__custom .nav-tabs::-webkit-scrollbar {
    display: none;
  }
  .tabs__custom .nav-tabs .nav-item:nth-child(1) {
    padding-left: 16px;
  }
  .tabs__custom .nav-tabs .nav-item:last-child {
    padding-right: 16px;
  }
  .tabs__custom .nav-tabs .nav-link {
    font-size: 14px;
    line-height: 18px;
    justify-content: center;
    padding: 0 6px;
    white-space: nowrap;
  }

  .content__box-title {
    margin-bottom: 32px;
    font-size: 21px;
    line-height: 24px;
  }

  .tabs__custom {
    margin-bottom: 48px;
  }

  .content__box:not(:last-child) {
    margin-bottom: 64px;
  }

  .sc__search .container {
    padding-top: 96px;
    padding-bottom: 16px;
  }
  .sc__search .search__box {
    height: 46px;
  }
  .sc__search .search__field input {
    height: 46px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 2px solid #23d1ae;
    padding-right: 48px;
    font-size: 15px;
    padding-left: 48px;
  }
  .sc__search .search__submit {
    left: 13px;
  }
  .sc__search .search__close {
    width: 48px;
    height: 48px;
  }

  .results__wrap .tabs__custom .nav-tabs:before {
    display: none;
  }
  .results__wrap .nav-tabs .nav-link {
    height: 48px;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .results__wrap .nav-tabs .nav-item {
    width: auto;
  }
  .results__wrap .tabs__custom {
    margin-bottom: 32px;
  }

  .results__item-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .results__item-descr {
    font-size: 14px;
    line-height: 18px;
  }

  .results__item:not(:last-child) {
    margin-bottom: 24px;
  }

  .results__list {
    margin-bottom: 32px;
    margin-top: 34px;
  }

  .sc__results .container {
    padding-bottom: 64px;
  }

  .decor-15 {
    margin-left: -216px;
    width: 457px;
    height: 496px;
    top: -310px;
  }

  .results__wrap {
    overflow: visible;
  }

  .article__item {
    margin-bottom: 2px;
  }

  .articles__list .row {
    margin-bottom: -2px;
  }

  .article__item-content {
    padding: 16px;
  }

  .article__item-title {
    font-size: 21px !important;
    line-height: 24px !important;
  }

  .article__item-category {
    margin-bottom: 16px;
  }

  .article__item-block {
    height: 60vw !important;
    max-height: 280px !important;
  }
}
@media screen and (max-width: 359px) {
  .promo__title {
    font-size: 28px;
    line-height: 36px;
  }
}

.internetforkids {
  display: flex;
}

.internetforkids img {
  display: block;
  width: 32px;
  height: 32px;
}
.internetforkids p {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #666;
  max-width: 300px;
  margin: 0 0 0 6px;
}
