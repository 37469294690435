.footer {
  margin-top: auto;
}

.quiz-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  font-family: KasperskySans, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.quiz-bg {
  padding-top: 104px;
  background: linear-gradient(126.73deg, #00A88E 0%, #6EF64A 100.25%);
}

.footer__top .container {
  padding-bottom: 20px;
}

.footer__bottom {
  padding: 28px 0;
}

.quiz-content {
  min-height: calc(100vh - 120px); /* screen height excludes footer */
}

.quiz-preview-description {
  flex: 588px 0 0;
}

.quiz-preview-img {
  flex: 668px 0 0;
  position: relative;
  z-index: 20;
}

.quiz-preview {
  position: relative;
  height: 696px;
  overflow: hidden;
}

.quiz-preview:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #ffffff;
  z-index: 10;
}

.quiz-preview-title {
  font-weight: bold;
  font-size: 72px;
  line-height: 1em;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin-bottom: 22px;
  margin-top: 49px;
}

.quiz-preview-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin-bottom: 55px;
}

.quiz-button {
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quiz-button-arrow:after {
  content: url("../../assets/img/quiz/arrow-right.svg");
  display: block;
  margin-left: 8px;
}

.quiz-list {
  min-height: calc(100vh - 120px);
}

.quiz-container {
  max-width: 792px;
  min-height: 600px;
  background: #ffffff;
  padding: 0 45px 34px;
  margin: 23px auto 50px;
  display: flex;
  flex-direction: column;
}
.quiz-img-left {
    min-width: 1000px;
    max-width: 1028px;
}
.box-quiz-content {
    width: 100%;
}
.quiz-img-left .box-quiz-content {
    width: 50%;
}
    .box-quiz-container {
    display: flex;
    flex-direction: row;
}

.quiz-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
}

.quiz-header-title {
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #00A88E;
}

.quiz-header-number {
  font-size: 18px;
  line-height: 1em;
  text-align: right;
  letter-spacing: -0.01em;
  color: #00A88E;
}

.quiz-question-text {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  background: linear-gradient(105deg, #2ACA93 0%, #6FF748 100.24%);
}

.quiz-question-text-inner {

  height: 100%;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
}

.quiz-question-img {
  height: auto;
  padding: 0px;
  text-align: center;
}

.quiz-question-img img {
  height: auto;
  max-width: 100%;
}
.quiz-question-img-top {
    padding-bottom: 20px;
}
.quiz-question-img-left {
    padding-right: 24px;
}
.quiz-question-img-title {
    height: 100%;
    padding: 6px;
}
.quiz-question-img-title img {
    height: 100%;
}

.quiz-question-answers {
    list-style: none;
    margin-bottom: 23px;
}

.quiz-question-answer {
  display: flex;
  align-items: center;
  background: #F8F8F8;
  height: 65px;
  padding: 12px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.quiz-question-answer:hover {
  background: #f3f3f3;
}

.quiz-question-answer-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.01em;
  color: #1D1D1B;
}

.quiz-question-answer-correct-1 .quiz-question-answer:nth-child(1),
.quiz-question-answer-correct-2 .quiz-question-answer:nth-child(2),
.quiz-question-answer-correct-3 .quiz-question-answer:nth-child(3),
.quiz-question-answer-correct-4 .quiz-question-answer:nth-child(4) {
  background: #00A88E;
}

.quiz-question-answer-wrong-1 .quiz-question-answer:nth-child(1),
.quiz-question-answer-wrong-2 .quiz-question-answer:nth-child(2),
.quiz-question-answer-wrong-3 .quiz-question-answer:nth-child(3),
.quiz-question-answer-wrong-4 .quiz-question-answer:nth-child(4) {
  background: #FF3748;
}

/* TODO: rewrite this dirty logic */
.quiz-question-answer-correct-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-text,
.quiz-question-answer-correct-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-text,
.quiz-question-answer-correct-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-text,
.quiz-question-answer-correct-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-text,
.quiz-question-answer-wrong-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-text,
.quiz-question-answer-wrong-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-text,
.quiz-question-answer-wrong-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-text,
.quiz-question-answer-wrong-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-text {
  color: #FFFFFF;
}

.quiz-question-answer-icon {
  margin-right: 17px;
  width: 41px;
  height: 41px;
  flex: 0 0 41px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  text-transform: uppercase;
}

/* TODO: rewrite this dirty logic */
.quiz-question-answer-correct-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-icon,
.quiz-question-answer-correct-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-icon,
.quiz-question-answer-correct-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-icon,
.quiz-question-answer-correct-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-icon,
.quiz-question-answer-wrong-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-icon,
.quiz-question-answer-wrong-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-icon,
.quiz-question-answer-wrong-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-icon,
.quiz-question-answer-wrong-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-icon {
  color: #00A88E;
}

.quiz-question-answer-icon-a {
  background-image: url("../../assets/img/quiz/a-icon.svg");
}

.quiz-question-answer-icon-b {
  background-image: url("../../assets/img/quiz/b-icon.svg");
}

.quiz-question-answer-icon-c {
  background-image: url("../../assets/img/quiz/c-icon.svg");
}

.quiz-question-answer-icon-d {
  background-image: url("../../assets/img/quiz/d-icon.svg");
}

/* TODO: rewrite this dirty logic */
.quiz-question-answer-correct-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-icon-a,
.quiz-question-answer-wrong-1 .quiz-question-answer:nth-child(1) .quiz-question-answer-icon-a {
  background-image: url("../../assets/img/quiz/a-icon-white.svg");
}

.quiz-question-answer-correct-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-icon-b,
.quiz-question-answer-wrong-2 .quiz-question-answer:nth-child(2) .quiz-question-answer-icon-b {
  background-image: url("../../assets/img/quiz/b-icon-white.svg");
}

.quiz-question-answer-correct-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-icon-c,
.quiz-question-answer-wrong-3 .quiz-question-answer:nth-child(3) .quiz-question-answer-icon-c {
  background-image: url("../../assets/img/quiz/c-icon-white.svg");
}

.quiz-question-answer-correct-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-icon-d,
.quiz-question-answer-wrong-4 .quiz-question-answer:nth-child(4) .quiz-question-answer-icon-d {
  background-image: url("../../assets/img/quiz/d-icon-white.svg");
}

.quiz-question-pagination {
  margin: auto auto 0;
  /*display: flex;*/
  list-style: none;
  text-align: center;
}

.quiz-question-pagination-item {
  width: 8px;
  height: 8px;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;
  margin: 0 6px;
  border-radius: 50%;
  display: inline-block;
}

.quiz-question-pagination-item-current {
  border: 1px solid #7EFF33;
  background: #FFFFFF;
}

.quiz-question-pagination-item-wrong {
  border: 1px solid #FF3748;
  background: #FF3748;
}

.quiz-question-pagination-item-correct {
  border: 1px solid #7EFF33;
  background: #7EFF33;
}

.quiz-results .quiz-container {
  position: relative;
  min-height: 0;
}

.quiz-results-img {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.quiz-results-img img {
  width: 100%;
}

.quiz-result {
  background: linear-gradient(109.51deg, #2ACA93 0%, #6FF748 100.24%);
  padding: 35px 350px 30px 40px;
  position: relative;
  min-height: 250px;
}

.quiz-result:after{
  content: '';
  background: url("../img/quiz-result-old.png") no-repeat center;
  background-size: contain;
  width: 335px;
  height: 230px;
  position: absolute;
  right: 15px;
  bottom: 0;
}

.test-mode-4 .quiz-result:after{
  background: url("../img/quiz-result.png") no-repeat center;
  background-size: contain;
}

.test-mode-5 .quiz-result:after{
  background: url("../img/quiz-result-5.png") no-repeat center;
  background-size: contain;
}

.test-mode-6 .quiz-result:after{
  background: url("../img/quiz-result-6.png") no-repeat center;
  background-size: contain;
}

.test-mode-7 .quiz-result:after {
    background: url("../img/quiz-result-7.png") no-repeat center;
    background-size: contain;
}

.test-mode-8 .quiz-result:after {
    background: url("../img/quiz-result-8.png") no-repeat center;
    background-size: contain;
}
.quiz__test7 .quiz-preview-img {
    margin-top: 35px !important;
    margin-right: -82px;
}
.quiz__test8 .quiz-preview-img {
    margin-top: -6px !important;
    margin-right: -82px;
}

.quiz-result-value {
  font-size: 18px;
  line-height: 1em;
  color: #FFFFFF;
}

.quiz-result-text {
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  color: #FFFFFF;
}

.quiz-results-description {
  padding: 30px 11px 0;
}

.quiz-results-description p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1D1D1B;
  margin-bottom: 30px;
}

.quiz-results-btns {
  display: flex;
  margin-bottom: 20px;
}

.quiz-results-btns .quiz-button {
  margin-right: 20px;
}

.quiz-modal {
  background: rgba(29, 29, 27, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 140px;
  opacity: 0;
  z-index: -1000;
  transition: all ease 0.3s;
}

.quiz-modal-visible {
  opacity: 1;
  z-index: 1000;
}

.quiz-modal-inner {
  width: 780px;
  background: #FFFFFF;
  padding: 15px 40px 15px 310px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 410px;
}

.quiz-modal-content{
  text-align: left;
}

.quiz-modal-img {
  width: 254px;
  min-width: 254px;
  margin-right: 45px;
  z-index: 10;
  background: linear-gradient(156.59deg, #23D1AE 0%, #7EFF33 100%);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 35px;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
}

.quiz-modal-img:before{
  content: '';
  background: url("../img/quiz-mask-decor.svg") no-repeat center;
  background-size: contain;
  width: 364px;
  height: 388px;
  top: 50%;
  margin-top: -195px;
  position: absolute;
  left: -95px;
}

.quiz-modal-img img {
  width: 132px;
  display: block;
  position: relative;
  z-index: 4;
}

.quiz-modal-title {
  font-weight: bold;
  font-size: 26px;
  color: #1D1D1B;
  margin-bottom: 19px;
}

.quiz-modal-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1D1D1B;
  margin-bottom: 33px;
}

.quiz__chat .quiz-preview-img {
  margin-top: -63px;
}

.quiz-question-answers.net-mode{
  margin-left: -10px;
  margin-right: -10px;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}

.quiz-question-answers.net-mode .quiz-question-answer{
  width: calc(50% - 20px);
  min-width: calc(50% - 20px);
  margin-right: 10px;
  margin-left: 10px;
  height: 135px;
}

.quiz__answer-img{
  width: 135px;
  margin-left: 30px;
  min-width: 135px;
}

.quiz__answer-img img{
  display: block;
  width: 100%;
}

@media screen and (min-width: 768px){
  .quiz-container{
    min-width: 760px;
  }
}

@media screen and (max-width: 1199px){
  .quiz-preview-description {
    flex: 441px 0 0;
  }

  .quiz-preview-img {
    flex: 550px 0 0;
    bottom: -115px;
  }

  .quiz-preview-img img {
    max-width: 100%;
  }

  .quiz-preview-title {
    margin-top: 29px;
    font-size: 56px;
  }

  .quiz-preview-text {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 991px) {
    .quiz-img-left .box-quiz-container {
        flex-direction: column;
    }
    .quiz-img-left .quiz-question-img-left {
        padding: 0 0 20px;
    }
    .quiz-img-left .box-quiz-content {
        width: 100%;
    }
    .quiz-content {
        height: calc(100vh - 190px);
        min-height: calc(100vh - 190px);
    }

  .quiz-preview {
    height: 100%;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
  }

  .quiz-preview .container {
    height: 100%;
  }

  .quiz-preview:before {
    display: none;
  }

  .quiz-preview-title {
    font-size: 48px;
  }

  .quiz-preview-description {
    flex: 550px 0 0;
    margin: auto;
  }

  .quiz-preview-img {
    display: none;
  }

  .quiz-container {
    min-height: 560px;
  }

  .quiz-question-text {
    min-height: 130px;
  }

  .quiz-question-text-inner {
    padding: 10px 15px;
    font-size: 18px;
    line-height: 22px;
    min-height: 130px;
  }

  .quiz-modal-title {
    font-size: 22px;
  }

  .quiz-results-description {
    padding: 30px 0 0;
  }

  .quiz-result-text {
    font-size: 26px;
  }

  .quiz-result {
    padding: 35px 180px 30px 40px;
  }

  .quiz-result:after {
    width: 259px;
    height: 178px;
    right: 0;
    bottom: 0;
  }

  .quiz-modal-inner {
    width: 664px;
  }
}

@media screen and (max-width: 767px) {
    .quiz__answer-img {
        width: 75px;
        margin-left: 0px;
        min-width: 75px;
    }
  .quiz-content {
    height: auto;
    min-height: calc(100vh - 190px);
  }

  .quiz-container {
    padding: 0 15px 34px;
  }

  .quiz-question-answer-text {
    font-size: 16px;
  }

  .quiz-modal {
    padding-top: 55px;
    bottom: auto;
    min-height: 100vh;
  }

  .quiz-modal-inner {
    padding: 70px 20px 30px;
  }

  .quiz-modal-img {
    top: -25px;
    width: 65px;
  }

  .quiz-modal-inner {
    width: 100%;
    flex-wrap: wrap;
    padding: 10px;
  }
}

@media screen and (max-width: 639px) {
  .quiz-question-answer {
    min-height: 65px;
    padding: 8px 10px;
  }

  .quiz-modal {
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow-y: auto;
    bottom: 0;
    display: block;
  }

  .quiz-modal-inner {
    width: 90%;
    margin: 35px auto;
  }

  .quiz-modal-text {
    font-size: 16px;
    line-height: 20px;
  }

  .quiz-question-text {
    height: auto;
    min-height: 130px;
    flex-wrap: wrap;;
  }

  .quiz-question-img {
    height: auto;
    width: 100%;
  }

  .quiz-question-img img {
    height: auto;
    width: 100%;
    display: block;
  }

  .quiz-header-title {
    font-size: 16px;
  }

  .quiz-header-number {
    font-size: 16px;
  }

  .quiz-result {
    padding: 20px 15px 80px;
  }

  .quiz-result-text {
    font-size: 22px;
  }

  .quiz-results .quiz-container {
    padding-bottom: 0;
  }

  .quiz-results-btns {
    flex-wrap: wrap;
  }

  .quiz-results-btns .quiz-button {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .quiz-preview-wrap .quiz-preview-title {
    margin-right: 0;
  }

  .quiz-preview-title {
    font-size: 34px;
  }

  .quiz-header-number {
    white-space: nowrap;
  }

  .quiz-result:after {
    width: 159px;
    height: 109px;
    right: 0;
    bottom: 0;
  }

  .quiz-bg {
    padding-top: 75px;
  }

  .quiz-modal-img {
    width: 100%;
    min-width: 100%;
    min-height: 90px;
    margin-right: 0;
    top: 0;
    padding-left: 0;
    margin-bottom: 24px;
    justify-content: center !important;
    position: relative;
    left: 0;
    bottom: 0;
  }

  .quiz-modal-img img {
    width: 56px;
  }

  .quiz-modal-text {
    margin-bottom: 20px;
  }

  .quiz-modal-img:before {
    width: 169px;
    height: 167px;
    top: 50%;
    margin-top: -80px;
    left: 50%;
    margin-left: -85px;
  }

  .quiz-modal-text {
    font-size: 14px;
    line-height: 19px;
  }
}